import _styled from "styled-components";
import { TextIconColor } from '../core/constants';
import { SortIcon } from '../core/Icon/SortIcon';
import { Typography, TypographyCasing, TypographySize, TypographyWeight, TypographyWrap } from '../core/Typography';
export type TableSort<SortKey extends string = string> = {
  direction: 'asc' | 'desc';
  key: SortKey;
};
interface SortHeaderProps<SortKey extends string> {
  activeSort: TableSort<SortKey>;
  setSortKey: (value: TableSort<SortKey>) => void;
  sortKey: SortKey;
  title: string;
  color?: TextIconColor;
  disabled?: boolean;
}
const SortHeaderContainer = _styled.div({
  "display": "flex",
  "width": "fit-content",
  "cursor": "pointer",
  "userSelect": "none",
  "alignItems": "center",
  "gap": "0.25rem"
});
export const Header: React.FC<{
  title: string;
  color?: TextIconColor;
  isActive?: boolean;
  isSortable?: boolean;
}> = ({
  title,
  color,
  isActive,
  isSortable = false
}) => <Typography casing={TypographyCasing.UPPERCASE} color={color || TextIconColor.PRIMARY} size={TypographySize.X_SMALL} weight={isActive ? TypographyWeight.MEDIUM : TypographyWeight.NORMAL} wrap={isSortable ? TypographyWrap.NO_WRAP : TypographyWrap.NORMAL}>
    {title}
  </Typography>;
export const CsvHeader: React.FC<{
  title: string;
}> = ({
  title
}) => <Typography color={TextIconColor.PRIMARY} size={TypographySize.MEDIUM} weight={TypographyWeight.SEMI_BOLD}>
    {title}
  </Typography>;
function SortHeader<SortKey extends string>({
  activeSort,
  sortKey,
  setSortKey,
  title,
  disabled,
  color = TextIconColor.PRIMARY
}: SortHeaderProps<SortKey>) {
  const isActive = activeSort.key === sortKey;
  const isDesc = activeSort.direction === 'desc';
  const onClick = () => {
    if (isActive) {
      setSortKey({
        key: sortKey,
        direction: isDesc ? 'asc' : 'desc'
      });
    } else {
      setSortKey({
        key: sortKey,
        direction: 'asc'
      });
    }
  };
  return <SortHeaderContainer onClick={!disabled ? onClick : undefined}>
      <Header color={color} isActive={isActive} isSortable title={title} />
      <SortIcon activeColor={TextIconColor.HIGHLIGHT} baseColor={color} isActive={isActive} isDesc={isDesc} />
    </SortHeaderContainer>;
}
export default SortHeader;