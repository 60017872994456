import _styled from "styled-components";
import { TextareaAutosize } from '@mui/material';
export enum TextFieldType {
  DEFAULT = 'DEFAULT',
  HIGHLIGHT = 'HIGHLIGHT',
  SIMPLE = 'SIMPLE',
}
const BASE_TEXT_FIELD_CONTAINER_STYLE = {
  "display": "flex",
  "width": "100%",
  "alignItems": "center",
  "borderRadius": "0.25rem",
  "borderWidth": "0.063rem",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(0 0 0 / var(--tw-bg-opacity))",
  "paddingTop": "0.25rem",
  "paddingBottom": "0.25rem",
  "paddingLeft": "0.5rem",
  "paddingRight": "1rem",
  "--tw-text-opacity": "1",
  "color": "rgb(255 255 255 / var(--tw-text-opacity))",
  "outline": "2px solid transparent",
  "outlineOffset": "2px",
  "transitionProperty": "color, background-color, border-color, text-decoration-color, fill, stroke",
  "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
  "transitionDuration": "100ms",
  "&:focus-within": {
    "--tw-border-opacity": "1",
    "borderColor": "rgb(141 118 255 / var(--tw-border-opacity))"
  },
  "&:focus-within:hover": {
    "--tw-border-opacity": "1",
    "borderColor": "rgb(141 118 255 / var(--tw-border-opacity))"
  }
};
export const TEXT_FIELD_CONTAINER_STYLES: Record<TextFieldType, TwStyle> = {
  [TextFieldType.DEFAULT]: {
    "--tw-border-opacity": "0.3",
    "borderColor": "rgb(255 255 255 / var(--tw-border-opacity))",
    "&:hover": {
      "--tw-border-opacity": "0.7"
    }
  },
  [TextFieldType.HIGHLIGHT]: {
    "--tw-border-opacity": "1",
    "borderColor": "rgb(72 38 253 / var(--tw-border-opacity))",
    "&:hover": {
      "--tw-border-opacity": "1",
      "borderColor": "rgb(53 17 245 / var(--tw-border-opacity))"
    }
  },
  [TextFieldType.SIMPLE]: {
    "borderStyle": "none",
    "backgroundColor": "transparent"
  }
};
export const getTextFieldStyles = ({
  $error,
  $type,
  disabled
}: {
  $error: boolean;
  $type: TextFieldType;
  disabled: boolean;
}) => [BASE_TEXT_FIELD_CONTAINER_STYLE, TEXT_FIELD_CONTAINER_STYLES[$type], $error && {
  "--tw-border-opacity": "1",
  "borderColor": "rgb(239 68 68 / var(--tw-border-opacity))",
  "&:hover": {
    "--tw-border-opacity": "1",
    "borderColor": "rgb(239 68 68 / var(--tw-border-opacity))"
  },
  "&:focus": {
    "--tw-border-opacity": "1",
    "borderColor": "rgb(239 68 68 / var(--tw-border-opacity))"
  }
}, disabled && {
  "cursor": "not-allowed",
  "borderStyle": "none",
  "backgroundColor": "rgb(255 255 255 / 0.1)",
  "opacity": "0.7"
}];
export const StyledRoot = _styled.div<{
  $error: boolean;
  $type: TextFieldType;
  disabled: boolean;
}>(({
  disabled,
  $error,
  $type
}) => [{
  "width": "100%"
}, getTextFieldStyles({
  disabled,
  $error,
  $type
})]);
export const TextArea = _styled(TextareaAutosize)<{
  $error: boolean;
  $type: TextFieldType;
  disabled: boolean;
}>(({
  disabled,
  $error,
  $type
}) => [{
  "width": "100%",
  "resize": "none",
  "backgroundColor": "transparent",
  "outline": "2px solid transparent",
  "outlineOffset": "2px"
}, getTextFieldStyles({
  disabled,
  $error,
  $type
}), disabled && {
  "cursor": "not-allowed"
}]);
export const StyledInput = _styled.input<{
  disabled: boolean;
}>(({
  disabled
}) => [{
  "width": "100%",
  "backgroundColor": "transparent",
  "outline": "2px solid transparent",
  "outlineOffset": "2px"
}, disabled && {
  "cursor": "not-allowed"
}]);