import _styled from "styled-components";
import { Size } from '../constants';
import { IconButtonSize, IconButtonType } from './IconButton.consts';

/** Types Styles */

const ICON_BUTTON_TYPE_STYLES: Record<IconButtonType, TwStyle> = {
  [IconButtonType.NONE]: {
    "borderStyle": "none",
    "backgroundColor": "transparent",
    "&:hover svg": {
      "--tw-text-opacity": "1",
      "color": "rgb(141 118 255 / var(--tw-text-opacity))"
    }
  },
  [IconButtonType.OUTLINED]: {
    "borderWidth": "1px",
    "borderColor": "rgb(255 255 255 / 0.2)",
    "backgroundColor": "transparent",
    "&:enabled:hover": {
      "borderColor": "rgb(255 255 255 / 0.5)"
    }
  },
  [IconButtonType.OUTLINED_COLOR]: {
    "borderWidth": "1px",
    "--tw-border-opacity": "1",
    "borderColor": "rgb(72 38 253 / var(--tw-border-opacity))",
    "backgroundColor": "transparent",
    "&:enabled:hover": {
      "--tw-border-opacity": "1",
      "borderColor": "rgb(53 17 245 / var(--tw-border-opacity))",
      "--tw-shadow": "0px -1px 46.1px 0px #3C00FF",
      "--tw-shadow-colored": "0px -1px 46.1px 0px var(--tw-shadow-color)",
      "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)"
    }
  },
  [IconButtonType.SOLID]: {
    "backgroundColor": "rgb(255 255 255 / 0.1)",
    "&:enabled:hover": {
      "backgroundColor": "rgb(255 255 255 / 0.2)"
    }
  },
  [IconButtonType.SOLID_COLOR]: {
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgb(72 38 253 / var(--tw-bg-opacity))",
    "&:enabled:hover": {
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgb(53 17 245 / var(--tw-bg-opacity))",
      "--tw-shadow": "0px 4px 45px 0px #3C00FF",
      "--tw-shadow-colored": "0px 4px 45px 0px var(--tw-shadow-color)",
      "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)"
    },
    "&:disabled": {
      "backgroundColor": "rgb(255 255 255 / 0.1)"
    }
  },
  [IconButtonType.SOLID_GRADIENT]: {
    "backgroundImage": "linear-gradient(to right, var(--tw-gradient-stops))",
    "--tw-gradient-from": "#320DC4 var(--tw-gradient-from-position)",
    "--tw-gradient-to": "#8544D9 var(--tw-gradient-to-position)",
    "--tw-gradient-stops": "var(--tw-gradient-from), var(--tw-gradient-to)",
    "&:enabled:hover": {
      "opacity": "0.9",
      "--tw-shadow": "0px 4px 45px 0px #5900DE",
      "--tw-shadow-colored": "0px 4px 45px 0px var(--tw-shadow-color)",
      "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)"
    },
    "&:disabled": {
      "backgroundColor": "rgb(255 255 255 / 0.1)"
    }
  },
  [IconButtonType.SIMPLE]: {
    "borderStyle": "none",
    "backgroundColor": "transparent",
    "paddingLeft": "0px",
    "&:enabled:hover": {
      "opacity": "0.5"
    },
    "&:disabled": {
      "borderStyle": "none"
    }
  }
};

/** Size Styles */

export const ICON_BUTTON_SIZE_STYLES: Record<IconButtonSize, TwStyle> = {
  [Size.SMALL]: {
    "height": "1.75rem",
    "width": "1.75rem"
  },
  [Size.MEDIUM]: {
    "height": "2rem",
    "width": "2rem"
  },
  [Size.LARGE]: {
    "height": "2.5rem",
    "width": "2.5rem"
  }
};
export const StyledIconButton = _styled.button<{
  $growOnHover: boolean;
  $size: IconButtonSize;
  $type: IconButtonType;
  disabled: boolean;
}>(({
  $size,
  $type,
  $growOnHover,
  disabled
}) => [{
  "display": "flex",
  "alignItems": "center",
  "justifyContent": "center",
  "whiteSpace": "nowrap",
  "borderRadius": "0.375rem"
},
// Base styles
!disabled && {
  "&:hover": {
    "--tw-border-opacity": "1",
    "borderColor": "rgb(9 90 124 / var(--tw-border-opacity))"
  }
},
// Hover if not disabled
{
  "&:disabled": {
    "cursor": "not-allowed",
    "borderStyle": "dashed",
    "borderColor": "rgb(255 255 255 / 0.2)"
  }
},
// Disabled overrides
ICON_BUTTON_TYPE_STYLES[$type],
// Button type styles
ICON_BUTTON_SIZE_STYLES[$size],
// Button size styles
disabled && {
  "cursor": "not-allowed",
  "opacity": "0.8"
}, $growOnHover && {
  "transitionDuration": "300ms",
  "transitionTimingFunction": "cubic-bezier(0, 0, 0.2, 1)",
  "&:enabled:hover": {
    "opacity": "1"
  },
  "&:hover svg": {
    "--tw-scale-x": "1.25",
    "--tw-scale-y": "1.25",
    "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))",
    "--tw-text-opacity": "1",
    "color": "rgb(255 255 255 / var(--tw-text-opacity))",
    "transitionProperty": "color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter",
    "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
    "transitionDuration": "150ms"
  }
}]);