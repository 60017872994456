import _styled from "styled-components";
import { RequireOnlyOne } from '@kindo/universal';
import React from 'react';
import { TextIconColor, Size, ButtonSize } from '../constants';
import { withLink } from '../hocs';
import { Icon, Icons } from '../Icon';
import { LoadingSpinner, LoadingSpinnerSize } from '../LoadingSpinner';
import { ToolTip } from '../ToolTip';
import { TooltipPlacement } from '../ToolTip/ToolTip';
import { Typography, TypographyCasing, TypographyFont, TypographySize, TypographyWeight, TypographyWrap } from '../Typography';
import { ButtonType } from './Button.consts';
import { BUTTON_SIZE_ICON_SIZES, StyledButton } from './Button.styles';
const getTextColor = (disabled: boolean) => {
  if (disabled) return TextIconColor.DISABLED;
  return TextIconColor.PRIMARY;
};
export const ToolTipSpanWrapper = _styled.span<{
  $fullWidth: boolean;
}>`
  ${({
  $fullWidth
}) => $fullWidth && {
  "width": "100%"
}}
`;
interface BaseButtonProps {
  href: string;
  label: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  type: ButtonType;
  'data-id'?: string | undefined;
  disabled?: boolean | undefined;
  endIcon?: Icon | undefined;
  font?: TypographyFont;
  fullWidth?: boolean;
  loading?: boolean | undefined;
  // Number = pixels, string = tw class
  maxWidth?: number | TwStyle;
  prefix?: string;
  size?: ButtonSize;
  startIcon?: Icon | undefined;
  suffix?: string | undefined;
  textIconColor?: TextIconColor | undefined;
  tooltip?: string | undefined;
  typographySize?: TypographySize;
  weight?: TypographyWeight;
}

// Ensures that either onClick or href is provided
export type ButtonProps = RequireOnlyOne<BaseButtonProps, 'onClick' | 'href'>;
const Button: React.FC<ButtonProps> = ({
  label,
  type,
  onClick,
  href,
  'data-id': dataId,
  disabled = false,
  endIcon,
  fullWidth = false,
  font = TypographyFont.INTERACTIVE,
  loading = false,
  maxWidth = {
    "maxWidth": "100%"
  },
  size = Size.MEDIUM,
  startIcon,
  prefix,
  suffix,
  textIconColor,
  tooltip,
  typographySize = TypographySize.SMALL,
  weight
}) => {
  const iconTextColor = textIconColor ?? getTextColor(disabled);
  const disableOnClick = disabled || loading;
  const buttonWeight = weight || (type === ButtonType.TEXT ? TypographyWeight.SEMI_BOLD : TypographyWeight.MEDIUM);
  return withLink(<ToolTip content={tooltip ?? ''} offset={[0, 8]} placement={TooltipPlacement.TOP_START}>
      <ToolTipSpanWrapper $fullWidth={fullWidth}>
        <StyledButton $fullWidth={fullWidth} $hasEndIcon={!!endIcon} $hasStartIcon={!!startIcon} $maxWidth={maxWidth} $size={size} $type={type} data-id={dataId} disabled={disabled} onClick={disableOnClick ? undefined : onClick}>
          {!loading && <>
              {prefix && <Typography casing={TypographyCasing.UPPERCASE} color={TextIconColor.HIGHLIGHT} font={font} size={typographySize} weight={buttonWeight}>
                  {prefix + ' '}
                </Typography>}
              {startIcon && <Icons color={iconTextColor} icon={startIcon} size={BUTTON_SIZE_ICON_SIZES[size]} />}
              <Typography casing={TypographyCasing.UPPERCASE} color={iconTextColor} font={font} size={typographySize} weight={buttonWeight} wrap={TypographyWrap.NO_WRAP}>
                {label}
                {suffix && <Typography casing={TypographyCasing.UPPERCASE} color={TextIconColor.SECONDARY} size={typographySize} weight={TypographyWeight.LIGHT}>
                    {' ' + suffix}
                  </Typography>}
              </Typography>
              {endIcon && <Icons color={iconTextColor} icon={endIcon} size={BUTTON_SIZE_ICON_SIZES[size]} />}
            </>}
          {loading && <LoadingSpinner size={LoadingSpinnerSize.SMALL} />}
        </StyledButton>
      </ToolTipSpanWrapper>
    </ToolTip>, {
    href
  });
};
export default Button;