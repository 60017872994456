/**
 * Currently, error codes are simple strings representing different error messages.
 *
 * This approach allows us to define user facing error messages in one place,
 * which will be populated in the "kindoErrorMessage" in our TRPC router.
 *
 * IMPORTANT: Ensure that ErrorCodes are unique, and do not overlap or else the spread will
 * overwrite the previous error message.
 *
 * IMPORTANT: Error codes that exist in the Python error_codes_consts.py file MUST exist in this
 * TypeScript errorCodes.consts.ts file.
 */

/** Chat Error Codes */
export enum ChatErrorCode {
  CHAT_CONTENT_FILTERED = 'CHAT_CONTENT_FILTERED',
  CHAT_CONTEXT_WINDOW_EXCEEDED = 'CHAT_CONTEXT_WINDOW_EXCEEDED',
  CHAT_CSV_PROCESSING_ERROR = 'CHAT_CSV_PROCESSING_ERROR',
  CHAT_FAILED_TO_CREATE_OR_UPLOAD_OUTPUT_FILE = 'CHAT_FAILED_TO_CREATE_OR_UPLOAD_FILE',
  CHAT_FILE_ACCESS_ERROR = 'CHAT_FILE_ACCESS_ERROR',
  CHAT_INSUFFICIENT_CREDITS = 'CHAT_INSUFFICIENT_CREDITS',
  CHAT_INTEGRATION_ACTION_ERROR = 'CHAT_INTEGRATION_ACTION_ERROR',
  CHAT_PROVIDER_OUTAGE = 'CHAT_PROVIDER_OUTAGE',
  CHAT_RATE_LIMITED = 'CHAT_RATE_LIMITED',
  CHAT_UNEXPECTED_ERROR = 'CHAT_UNEXPECTED_ERROR',
  CHAT_UNSUPPORTED_FILE_TYPE = 'CHAT_UNSUPPORTED_FILE_TYPE'
}

export const CHAT_ERROR_MESSAGES: Record<ChatErrorCode, string> = {
  [ChatErrorCode.CHAT_CONTENT_FILTERED]:
    "This message has triggered the model's content filtering system. Try modifying your message and/or files, using a different model, or starting a new chat.",
  [ChatErrorCode.CHAT_CONTEXT_WINDOW_EXCEEDED]:
    'This message causes the chat to exceed the maximum number of words this model can handle. Try writing a shorter message, using less/shorter files, or creating a new chat. Other models may have a higher maximum.',
  [ChatErrorCode.CHAT_CSV_PROCESSING_ERROR]:
    'CSV row by row processing requires a CSV file. Please contact support if this problem continues.',
  [ChatErrorCode.CHAT_FAILED_TO_CREATE_OR_UPLOAD_OUTPUT_FILE]:
    'An error occurred while uploading or creating the file. Please contact support if this problem continues.',
  [ChatErrorCode.CHAT_FILE_ACCESS_ERROR]:
    'An error occurred when trying to access your file. Please contact support if this problem continues.',
  [ChatErrorCode.CHAT_INSUFFICIENT_CREDITS]:
    "You don't have enough credits to send this message. Reach out to our sales team for more, or wait for the daily credit refill.",
  [ChatErrorCode.CHAT_INTEGRATION_ACTION_ERROR]:
    'An error occurred when trying to perform the integration action. Please contact support if this problem continues.',
  [ChatErrorCode.CHAT_RATE_LIMITED]:
    'The model is experiencing a high volume of messages. Please try again later, or use a different model.',
  [ChatErrorCode.CHAT_UNEXPECTED_ERROR]:
    'Something went wrong. Please try again, and contact support if it continues.',
  [ChatErrorCode.CHAT_UNSUPPORTED_FILE_TYPE]:
    'The type of an attached file is not supported by this model. Please try using a different file type or model.',
  [ChatErrorCode.CHAT_PROVIDER_OUTAGE]:
    'The model is currently unavailable. Please try again later, or use a different model.'
} as const;

/** Model Access Error Codes */
export enum ModelAccessErrorCode {
  MODEL_ACCESS_DENIED = 'MODEL_ACCESS_DENIED',
  MODEL_ACCESS_DENIED_FOR_ORG = 'MODEL_ACCESS_DENIED_FOR_ORG',
  MODEL_ACCESS_DENIED_FOR_PROVIDER_FOR_ORG = 'MODEL_ACCESS_DENIED_FOR_PROVIDER_FOR_ORG'
}

/** Chat Message Error Messages */
export enum ChatMessageErrorCode {
  CHAT_MESSAGE_NOT_FOUND = 'CHAT_MESSAGE_NOT_FOUND'
}

export const MODEL_ACCESS_ERROR_MESSAGES: Record<ModelAccessErrorCode, string> =
  {
    [ModelAccessErrorCode.MODEL_ACCESS_DENIED]: 'Access denied to this model.',
    [ModelAccessErrorCode.MODEL_ACCESS_DENIED_FOR_ORG]:
      'Access denied to this model for your organization.',
    [ModelAccessErrorCode.MODEL_ACCESS_DENIED_FOR_PROVIDER_FOR_ORG]:
      'Access denied to models from this provider for your organization.'
  } as const;

/** Org */
export enum OrgErrorCode {
  ORG_CANNOT_INVITE_ALREADY_IN_AN_ORG = 'ORG_CANNOT_INVITE_ALREADY_IN_AN_ORG',
  ORG_INVITE_ALREADY_EXISTS = 'ORG_INVITE_ALREADY_EXISTS',
  ORG_INVITE_NOT_FOUND = 'ORG_INVITE_NOT_FOUND',
  ORG_MEMBER_LIMIT_REACHED = 'ORG_MEMBER_LIMIT_REACHED'
}

const ORG_ERROR_MESSAGES: Record<OrgErrorCode, string> = {
  [OrgErrorCode.ORG_CANNOT_INVITE_ALREADY_IN_AN_ORG]:
    'Cannot invite a user who is already in an organization.',
  [OrgErrorCode.ORG_INVITE_NOT_FOUND]: 'Invite is no longer valid.',
  [OrgErrorCode.ORG_INVITE_ALREADY_EXISTS]:
    'This user has already been invited.',
  [OrgErrorCode.ORG_MEMBER_LIMIT_REACHED]:
    'Maximum number of members reached for your organization.'
} as const;

/** Workflows */
export enum WorkflowErrorCode {
  DELETE_WORKFLOW_TRIGGER_WITH_TICKETING_INTEGRATION_TICKET_STEP_INPUTS = 'DELETE_WORKFLOW_TRIGGER_WITH_TICKETING_INTEGRATION_TICKET_STEP_INPUTS',
  ORG_WORKFLOW_LIMIT_REACHED = 'ORG_WORKFLOW_LIMIT_REACHED',
  WORKFLOW_SHARING_USER_NOT_FOUND = 'WORKFLOW_USER_NOT_FOUND',
  WORKFLOW_SHARING_USER_NOT_IN_ORG = 'WORKFLOW_SHARING_USER_NOT_IN_ORG',
  WORKFLOW_USER_INPUT_WITH_TRIGGER = 'WORKFLOW_USER_INPUT_WITH_TRIGGER'
}

const WORKFLOW_ERROR_MESSAGES: Record<WorkflowErrorCode, string> = {
  [WorkflowErrorCode.WORKFLOW_SHARING_USER_NOT_FOUND]:
    'User not found, please enter the email of an active Kindo user.',
  [WorkflowErrorCode.WORKFLOW_SHARING_USER_NOT_IN_ORG]:
    'User must be a member of your organization.',
  [WorkflowErrorCode.ORG_WORKFLOW_LIMIT_REACHED]:
    'Maximum number of agents reached for your organization.',
  [WorkflowErrorCode.WORKFLOW_USER_INPUT_WITH_TRIGGER]:
    'Cannot add user inputs to a workflow with an existing trigger.',
  [WorkflowErrorCode.DELETE_WORKFLOW_TRIGGER_WITH_TICKETING_INTEGRATION_TICKET_STEP_INPUTS]:
    'Cannot delete a trigger if there are workflow steps that reference the ticket input associated with that trigger.'
} as const;

// Mapping of all error codes to their corresponding messages
export const KINDO_ERROR_MESSAGES = {
  ...CHAT_ERROR_MESSAGES,
  ...MODEL_ACCESS_ERROR_MESSAGES,
  ...ORG_ERROR_MESSAGES,
  ...WORKFLOW_ERROR_MESSAGES
} as const;

export type KindoErrorCode = keyof typeof KINDO_ERROR_MESSAGES;

export function isKindoErrorCode(
  errorCode: string
): errorCode is KindoErrorCode {
  return errorCode in KINDO_ERROR_MESSAGES;
}

export function getKindoErrorMessage(errorCode: KindoErrorCode) {
  return KINDO_ERROR_MESSAGES[errorCode];
}
