import _styled from "styled-components";
import { SegmentedControlType } from './SegmentedControl.consts';
export const StyledSegmentedControl = _styled.div<{
  $type: SegmentedControlType;
}>(({
  $type
}) => [
// Base style
{
  "display": "flex",
  "width": "100%",
  "justifyContent": "center",
  "borderRadius": "0.375rem",
  "borderWidth": "0.063rem",
  "transitionProperty": "all",
  "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
  "transitionDuration": "100ms"
}, $type === SegmentedControlType.SOLID_HIGHLIGHT && {
  "display": "inline-flex",
  "maxWidth": "480px",
  "borderColor": "#6761fa5e",
  ">*:not(:last-child)": {
    "borderRightWidth": "0.063rem",
    "borderRightColor": "#6761fa5e"
  }
}, $type === SegmentedControlType.SOLID && {
  "display": "flex",
  "width": "100%",
  "alignItems": "center",
  "justifyContent": "center",
  "gap": "3px",
  "--tw-border-opacity": "1",
  "borderColor": "rgb(52 45 137 / var(--tw-border-opacity))",
  "padding": "3px",
  "&:hover": {
    "--tw-border-opacity": "1",
    "borderColor": "rgb(141 118 255 / var(--tw-border-opacity))"
  }
}]);
export const getSegmentTypeStyle = ($type: SegmentedControlType, $isSelected: boolean, $segmentIndex: number, $numSegments: number) => {
  if ($type === SegmentedControlType.SOLID) {
    return [{
      "width": "100%",
      "borderRadius": "4px"
    }, $isSelected ? {
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgb(52 45 137 / var(--tw-bg-opacity))"
    } : {
      "&:hover": {
        "backgroundColor": "rgb(255 255 255 / 0.1)"
      }
    }];
  }
  // else type = SegmentedControlType.PRIMARY
  return [{
    "display": "inline-flex",
    "width": "10rem"
  }, $segmentIndex === 0 && {
    "borderTopLeftRadius": "0.375rem",
    "borderBottomLeftRadius": "0.375rem"
  }, $segmentIndex === $numSegments - 1 && {
    "borderTopRightRadius": "0.375rem",
    "borderBottomRightRadius": "0.375rem"
  }, $isSelected && {
    "backgroundImage": "linear-gradient(to right, var(--tw-gradient-stops))",
    "--tw-gradient-from": "#320DC4 var(--tw-gradient-from-position)",
    "--tw-gradient-to": "#8544D9 var(--tw-gradient-to-position)",
    "--tw-gradient-stops": "var(--tw-gradient-from), var(--tw-gradient-to)",
    "--tw-shadow": "0 0 20px rgba(60, 0, 255, 1)",
    "--tw-shadow-colored": "0 0 20px var(--tw-shadow-color)",
    "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)"
  }];
};
export const StyledSegment = _styled.div<{
  $isSelected: boolean;
  $numSegments: number;
  $segmentIndex: number;
  $type: SegmentedControlType;
}>(({
  $type,
  $isSelected,
  $segmentIndex,
  $numSegments
}) => [
// Base style
{
  "alignItems": "center",
  "justifyContent": "center",
  "gap": "0.5rem",
  "paddingLeft": "0.125rem",
  "paddingRight": "0.125rem",
  "paddingTop": "0.25rem",
  "paddingBottom": "0.25rem",
  "textAlign": "center",
  "outline": "2px solid transparent",
  "outlineOffset": "2px"
}, getSegmentTypeStyle($type, $isSelected, $segmentIndex, $numSegments), $isSelected ? {
  "cursor": "default"
} : {
  "cursor": "pointer",
  "transitionProperty": "color, background-color, border-color, text-decoration-color, fill, stroke",
  "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
  "transitionDuration": "100ms"
}]);