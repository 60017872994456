import { DlpFiltersConfig } from '@prisma/client';

import { ExternalLlm, Integration, Llm, Provider } from '../constants';

export type AdminSecurityConfigResource = Integration | Llm | Provider;

export enum AdminSecurityConfigResourceType {
  INTEGRATION = 'INTEGRATION',
  MODEL = 'MODEL',
  // MODEL_AND_INTEGRATION = 'MODEL_AND_INTEGRATION',
  PROVIDER = 'PROVIDER'
  // PROVIDER_AND_INTEGRATION = 'PROVIDER_AND_INTEGRATION'
}

export interface AdminSecurityConfigResourceTypeToResource {
  [AdminSecurityConfigResourceType.INTEGRATION]: Integration;
  [AdminSecurityConfigResourceType.MODEL]: Llm;
  [AdminSecurityConfigResourceType.PROVIDER]: Provider;
}

export interface AdminSecurityConfigResourceTypeToConfig {
  [AdminSecurityConfigResourceType.INTEGRATION]: IntegrationSecurityConfig;
  [AdminSecurityConfigResourceType.MODEL]: ModelSecurityConfig;
  [AdminSecurityConfigResourceType.PROVIDER]: ProviderSecurityConfig;
}

export interface BaseResourceSecurityConfig {
  accessEnabled: boolean;
  dlpEnabled: boolean;
  dlpFiltersConfig: DlpFiltersConfig | null;
  hasCustomDlpFiltersConfig: boolean;
}

export interface ProviderSecurityConfig extends BaseResourceSecurityConfig {
  provider: Provider;
}
export interface ModelSecurityConfig extends BaseResourceSecurityConfig {
  model: ExternalLlm;
}

export interface IntegrationSecurityConfig extends BaseResourceSecurityConfig {
  integration: Integration;
}

// Type guards
export const isIntegrationSecurityConfig = (
  config: AdminSecurityConfigResourceTypeToConfig[AdminSecurityConfigResourceType]
): config is AdminSecurityConfigResourceTypeToConfig[AdminSecurityConfigResourceType.INTEGRATION] =>
  'integration' in config;

export const isModelSecurityConfig = (
  config: AdminSecurityConfigResourceTypeToConfig[AdminSecurityConfigResourceType]
): config is AdminSecurityConfigResourceTypeToConfig[AdminSecurityConfigResourceType.MODEL] =>
  'model' in config;

export const isProviderSecurityConfig = (
  config: AdminSecurityConfigResourceTypeToConfig[AdminSecurityConfigResourceType]
): config is AdminSecurityConfigResourceTypeToConfig[AdminSecurityConfigResourceType.PROVIDER] =>
  'provider' in config;

// Generics + tRPC procedures essentially always return Union Types and don't allow for clients to
// type narrow a specific subclass without just explicit casting. This is an elaborate, gross
// typeguard to ensure that the correct type is returned.
export const validateSecurityConfigs = <
  T extends AdminSecurityConfigResourceType
>(
  configs: AdminSecurityConfigResourceTypeToConfig[AdminSecurityConfigResourceType][],
  resourceType: T
): AdminSecurityConfigResourceTypeToConfig[T][] => {
  switch (resourceType) {
    case AdminSecurityConfigResourceType.INTEGRATION:
      if (!configs.every(isIntegrationSecurityConfig)) {
        throw new Error('Invalid integration security config');
      }
      return configs as AdminSecurityConfigResourceTypeToConfig[T][];
    case AdminSecurityConfigResourceType.MODEL:
      if (!configs.every(isModelSecurityConfig)) {
        throw new Error('Invalid model security config');
      }
      return configs as AdminSecurityConfigResourceTypeToConfig[T][];
    case AdminSecurityConfigResourceType.PROVIDER:
      if (!configs.every(isProviderSecurityConfig)) {
        throw new Error('Invalid provider security config');
      }
      return configs as AdminSecurityConfigResourceTypeToConfig[T][];
    default:
      throw new Error('Invalid resource type');
  }
};

export const INTEGRATION_ACCESS_ENABLED_DEFAULTS: Record<Integration, boolean> =
  {
    [Integration.AZURE_DEVOPS]: true,
    [Integration.BOX]: true,
    [Integration.DROPBOX]: true,
    [Integration.GITHUB_ISSUES]: true,
    [Integration.GOOGLE_DRIVE]: true,
    [Integration.JIRA]: true,
    [Integration.LINEAR]: true,
    [Integration.ONEDRIVE]: true,
    [Integration.SERVICENOW]: true,
    [Integration.SLACK]: true
  };

export const INTEGRATION_DLP_ENABLED_DEFAULTS: Record<Integration, boolean> = {
  [Integration.AZURE_DEVOPS]: false,
  [Integration.BOX]: false,
  [Integration.DROPBOX]: false,
  [Integration.GITHUB_ISSUES]: false,
  [Integration.GOOGLE_DRIVE]: false,
  [Integration.JIRA]: false,
  [Integration.LINEAR]: false,
  [Integration.ONEDRIVE]: false,
  [Integration.SERVICENOW]: false,
  [Integration.SLACK]: false
};
