import _styled from "styled-components";
import { Size, TEXT_COLORS, TextIconColor } from '../../constants';
import SORT_SVG from '../assets/sort.icon.svg';
import { getIconSize } from '../Icons';
interface SortIconProps {
  baseColor: TextIconColor;
  activeColor?: TextIconColor;
  isActive?: boolean;
  isDesc?: boolean;
  size?: Size | number | undefined;
}
const getArrowColor = (isActive: boolean, isDesc: boolean, activeColor: TextIconColor, color: TextIconColor) => {
  if (isActive) {
    return isDesc ? {
      up: TEXT_COLORS[color],
      down: TEXT_COLORS[activeColor]
    } : {
      up: TEXT_COLORS[activeColor],
      down: TEXT_COLORS[color]
    };
  }
  return {
    up: TEXT_COLORS[color],
    down: TEXT_COLORS[color]
  };
};
const SortIconContainer = _styled.span<{
  $activeColor: TextIconColor;
  $color: TextIconColor;
  $isActive: boolean;
  $isDesc: boolean;
  $size: Size | number;
}>`
  ${({
  $size
}) => getIconSize($size)}

  .sort_icon_svg__sort-up {
    ${({
  $activeColor,
  $color,
  $isActive,
  $isDesc
}) => getArrowColor($isActive, $isDesc, $activeColor, $color).up}
  }

  .sort_icon_svg__sort-down {
    ${({
  $activeColor,
  $color,
  $isActive,
  $isDesc
}) => getArrowColor($isActive, $isDesc, $activeColor, $color).down}
  }
`;
const SortIcon: React.FC<SortIconProps> = ({
  activeColor,
  baseColor,
  size = Size.LARGE,
  isActive = false,
  isDesc = false
}) => <SortIconContainer $activeColor={activeColor || baseColor} $color={baseColor} $isActive={isActive} $isDesc={isDesc} $size={size}>
    <SORT_SVG />
  </SortIconContainer>;
export default SortIcon;