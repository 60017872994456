import _styled from "styled-components";
import { GiantButtonType } from './GiantButton.consts';
const GIANT_BUTTON_TYPE_STYLES: Record<GiantButtonType, TwStyle> = {
  [GiantButtonType.PRIMARY]: {
    "borderWidth": "1px",
    "--tw-border-opacity": "1",
    "borderColor": "rgb(72 38 253 / var(--tw-border-opacity))",
    "backgroundColor": "rgba(9, 9, 13, 0.50)",
    "--tw-shadow": "inset 0px 0px 40px 0px rgba(32,0,200,0.30)",
    "--tw-shadow-colored": "inset 0px 0px 40px 0px var(--tw-shadow-color)",
    "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
    "&:hover": {
      "--tw-border-opacity": "1",
      "borderColor": "rgb(141 118 255 / var(--tw-border-opacity))",
      "--tw-shadow": "inset 0px 0px 40px 0px #2000C8",
      "--tw-shadow-colored": "inset 0px 0px 40px 0px var(--tw-shadow-color)",
      "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)"
    }
  },
  [GiantButtonType.SECONDARY]: {
    "borderWidth": "1px",
    "borderColor": "rgb(255 255 255 / 0.2)",
    "backgroundColor": "transparent",
    "&:enabled:hover": {
      "borderColor": "rgb(255 255 255 / 0.5)"
    }
  }
};
export const StyledGiantButton = _styled.button<{
  $type: GiantButtonType;
  disabled: boolean;
}>(({
  $type,
  disabled
}) => [{
  "display": "flex",
  "height": "3rem",
  "width": "100%",
  "alignItems": "center",
  "justifyContent": "center",
  "gap": "0.25rem",
  "whiteSpace": "nowrap",
  "borderRadius": "0.5rem",
  "paddingLeft": "0.5rem",
  "paddingRight": "0.5rem",
  "transitionProperty": "all",
  "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
  "transitionDuration": "100ms"
}, disabled ? {
  "cursor": "not-allowed",
  "borderRadius": "0.5rem",
  "borderWidth": "1.25px",
  "borderStyle": "dashed",
  "borderColor": "rgba(255,255,255,0.20)",
  "backgroundColor": "rgba(9,9,13,0.50)",
  "opacity": "0.5"
} : GIANT_BUTTON_TYPE_STYLES[$type]]);